import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';

import {ProjectTranslation} from '@bumpy/i18n_common';

type Props = {
  open: boolean;
  onClose: () => void;
  onKeysImported: (translation: ProjectTranslation) => void;
};

export const ImportKeysDialog: React.FC<Props> = (props) => {
  const [text, setText] = React.useState('');
  let translation: ProjectTranslation | undefined;
  try {
    translation = JSON.parse(text);
  } catch {}

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Import keys (JSON i18n)</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          fullWidth
          multiline
          label="JSON"
          margin="none"
          rows="10"
          value={text}
          variant="standard"
          onChange={(event) => {
            setText(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button
          disabled={translation === undefined}
          onClick={() => {
            props.onKeysImported(translation!);
          }}
        >
          Import
        </Button>
      </DialogActions>
    </Dialog>
  );
};
