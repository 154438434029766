import React from 'react';
import {NavLink, useParams} from 'react-router-dom';

import SaveIcon from '@mui/icons-material/Save';
import {Breadcrumbs, Button, Grid, Typography} from '@mui/material';

import {apiCall} from '../../utils';

import {ProjectForm} from './ProjectForm';

import {Project, ProjectModifyDTO} from '@bumpy/i18n_common';

export const ProjectEdit: React.FC = () => {
  const {projectUuid} = useParams();
  const [project, setProject] = React.useState<ProjectModifyDTO | undefined>(
    undefined,
  );

  React.useEffect(() => {
    apiCall({
      method: 'GET',
      url: `/projects/${projectUuid}`,
      secure: true,
    }).then((response) => setProject(response.data));
  }, [projectUuid]);

  const onChange = (diff: Partial<Project>) => {
    setProject({
      ...project!,
      ...diff,
    });
  };

  const onSaveClick = () => {
    if (!project!.chatgpt && !project!.deepl && !project!.googleTranslate) {
      alert('At least one translator must be used');
      return;
    }
    apiCall({
      method: 'PUT',
      url: `/projects/${projectUuid}`,
      secure: true,
      data: project,
    }).then(() => {
      alert('Project saved');
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={10}>
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink to="/">Projects</NavLink>
          <NavLink to={`/${projectUuid}`}>{project?.name ?? ''}</NavLink>
          <Typography color="secondary">Edit Project</Typography>
        </Breadcrumbs>
      </Grid>
      {project && (
        <>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={onSaveClick}
            >
              Save
            </Button>
          </Grid>
          <ProjectForm project={project} onChange={onChange} />
        </>
      )}
    </Grid>
  );
};
