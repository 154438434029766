import React from 'react';
import {createBrowserRouter} from 'react-router-dom';

import {ProjectCreate} from './pages/projects/ProjectCreate';
import {ProjectEdit} from './pages/projects/ProjectEdit';
import {ProjectInfo} from './pages/projects/ProjectInfo';
import {ProjectsList} from './pages/projects/ProjectsList';
import {ProjectRevisionForm} from './pages/revisions/ProjectRevisionForm';
import {SignInWithGoogle} from './pages/sign/SignInWithGoogle';

export const router = createBrowserRouter([
  {
    path: '/new',
    element: <ProjectCreate />,
  },
  {
    path: '/:projectUuid/edit',
    element: <ProjectEdit />,
  },
  {
    path: '/:projectUuid',
    element: <ProjectInfo />,
  },
  {
    path: '/:projectUuid/revisions/:revisionUuid',
    element: <ProjectRevisionForm />,
  },
  {
    path: '/sign',
    element: <SignInWithGoogle />,
  },
  {
    path: '/',
    element: <ProjectsList />,
  },
]);
