export function stringContent(content: any): string {
  if (typeof content === 'string') {
    return content;
  }
  return JSON.stringify(content);
}

export function filterWeirdSymbols(content: string): string {
  content = content.replace('\u2028', ' ');

  while (true) {
    const newContent = content.replace('  ', ' ');
    if (newContent === content) {
      return content;
    }
    content = newContent;
  }
}

export function mainLangFirst(langs: string[], mainLang: string): string[] {
  if (langs[0] !== mainLang) {
    const index = langs.indexOf(mainLang);
    langs.splice(index, 1);
    langs.unshift(mainLang);
  }
  return langs;
}
