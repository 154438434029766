import React from 'react';
import {NavLink, useNavigate, useParams} from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Sync';
import {
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import {
  apiCall,
  formatDate,
  hideLoader,
  InputDialog,
  showLoader,
  YesNoDialog,
} from '../../utils';

import {Project, ProjectRevisionInfo} from '@bumpy/i18n_common';

export const ProjectInfo: React.FC = () => {
  const navigate = useNavigate();
  const {projectUuid} = useParams();
  const [project, setProject] = React.useState<Project | undefined>(undefined);
  const [deleteRevision, setDeleteRevision] = React.useState<
    ProjectRevisionInfo | undefined
  >(undefined);
  const [newRevision, setNewRevision] = React.useState(false);

  const refresh = () => {
    apiCall({
      method: 'GET',
      url: `/projects/${projectUuid}`,
      secure: true,
    }).then((response) => setProject(response.data));
  };
  React.useEffect(refresh, [projectUuid]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Breadcrumbs aria-label="breadcrumb">
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink to="/">Projects</NavLink>
            <Typography color="secondary">{project?.name ?? ''}</Typography>
          </Breadcrumbs>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={refresh}
          startIcon={<RefreshIcon />}
        >
          Refresh
        </Button>
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setNewRevision(true);
          }}
          startIcon={<AddIcon />}
        >
          Revision
        </Button>
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            navigate('edit');
          }}
          startIcon={<EditIcon />}
        >
          Edit
        </Button>
      </Grid>
      {project && (
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Last modified</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {project.revisions!.map((revision) => (
                  <TableRow key={revision.uuid}>
                    <TableCell>{revision.name}</TableCell>
                    <TableCell>{formatDate(revision.modifiedAt)}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          setDeleteRevision(revision);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          navigate(`revisions/${revision.uuid}`);
                        }}
                      >
                        <ArrowForwardIosIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
      {deleteRevision && (
        <YesNoDialog
          title="Are you sure?"
          message={`Do you want to delete revision "${deleteRevision.name}"?`}
          onYes={() => {
            apiCall({
              method: 'DELETE',
              url: `/projects/${projectUuid}/revisions/${deleteRevision!.uuid}`,
              secure: true,
            }).then(refresh);
            setDeleteRevision(undefined);
          }}
          onNo={() => {
            setDeleteRevision(undefined);
          }}
        />
      )}
      {newRevision && (
        <InputDialog
          title="New revision"
          message={project?.git ? 'Enter branch name' : 'Enter revision name'}
          onPositive={(branch) => {
            showLoader('Creating revision');
            apiCall({
              method: 'POST',
              url: `/projects/${projectUuid}/revisions`,
              data: {branch},
              secure: true,
            })
              .then(refresh)
              .finally(hideLoader);
            setNewRevision(false);
          }}
          onNegative={() => {
            setNewRevision(false);
          }}
        />
      )}
    </Grid>
  );
};
