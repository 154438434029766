import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';

type Props = {
  branch: string;
  open: boolean;
  onClose: () => void;
  onCommit: (branch: string, message: string) => void;
};

export const CommitDialog: React.FC<Props> = (props) => {
  const [branch, setBranch] = React.useState(props.branch);
  const [message, setMessage] = React.useState('');

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Commit changes to git</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          fullWidth
          label="Git branch to push"
          value={branch}
          variant="standard"
          onChange={(event) => {
            setBranch(event.target.value);
          }}
        />
        <TextField
          autoFocus
          fullWidth
          label="Commit message"
          value={message}
          variant="standard"
          onChange={(event) => {
            setMessage(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button
          disabled={branch === 'main'}
          onClick={() => {
            props.onCommit(branch, message);
          }}
        >
          Commit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
