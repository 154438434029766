import React from 'react';
import {useNavigate} from 'react-router-dom';

import CreateIcon from '@mui/icons-material/Add';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Sync';
import {
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import {apiCall, formatDate} from '../../utils';

import {Project} from '@bumpy/i18n_common';

export const ProjectsList: React.FC = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = React.useState<Project[]>([]);

  const refresh = () => {
    apiCall({
      method: 'GET',
      url: '/projects',
      secure: true,
    }).then((response) => {
      setProjects(response.data.projects);
    });
  };

  React.useEffect(refresh, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="secondary">Projects</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={refresh}
          startIcon={<RefreshIcon />}
        >
          Refresh
        </Button>
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            navigate('new');
          }}
          startIcon={<CreateIcon />}
        >
          Create
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Last modified</TableCell>
                <TableCell>Format</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {projects.map((project) => (
                <TableRow key={project.uuid}>
                  <TableCell>{project.name}</TableCell>
                  <TableCell>{formatDate(project.modifiedAt)}</TableCell>
                  <TableCell>{project.format}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        navigate(`${project.uuid}/edit`);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        navigate(`${project.uuid}`);
                      }}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};
