import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';

import {apiCall, hideLoader, showLoader} from '../../utils';

type Props = {
  open: boolean;
  onClose: () => void;
  onNewLangCreate: (lang: string) => void;
};

export const NewLangDialog: React.FC<Props> = (props) => {
  const [lang, setLang] = React.useState('');

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Add new language</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          fullWidth
          label="Language code (2-5 symbols)"
          margin="none"
          value={lang}
          variant="standard"
          onChange={(event) => {
            setLang(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button
          onClick={() => {
            showLoader();
            apiCall({
              method: 'GET',
              url: `/translators/${lang}`,
              secure: true,
            })
              .then((response) => {
                if (response.data.ok) {
                  props.onNewLangCreate(lang);
                } else {
                  alert('Invalid language code');
                }
              })
              .finally(hideLoader);
          }}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
