import {NavigateFunction} from 'react-router/dist/lib/hooks';
import {CredentialResponse} from '@react-oauth/google';

import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';

import {deleteToken, getToken, saveToken} from './tokenStore';

import {SignInRequestDTO, SignInResponseDTO} from '@bumpy/i18n_common';

type Request = AxiosRequestConfig & {
  secure?: true;
};

function navigateToSign(): void {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.location = '/sign';
}

export function apiCall<T = any>(request: Request): Promise<AxiosResponse<T>> {
  return new Promise((resolve, reject) => {
    if (request.secure) {
      const Authorization = getToken();
      if (!Authorization) {
        navigateToSign();
        return;
      }
      request.headers = {Authorization};
    }
    request.baseURL = process.env.REACT_APP_BASE_URL;

    axios
      .request<T>(request)
      .then(resolve)
      .catch((error) => {
        if (error instanceof AxiosError && error.response?.status === 401) {
          navigateToSign();
        } else {
          reject(error);
        }
      });
  });
}

export function signInWithGoogle(
  google: CredentialResponse,
  navigate: NavigateFunction,
): void {
  const data: SignInRequestDTO = {
    token: google.credential!,
  };

  apiCall({
    method: 'POST',
    url: '/sign/in',
    data,
  })
    .then((response) => {
      if (response.status === 200) {
        const dto = response.data as SignInResponseDTO;
        saveToken(dto.token);
        navigate('/');
      } else {
        alert('User is not allowed');
      }
    })
    .catch((e) => {
      if (e instanceof AxiosError && e.response) {
        const {err} = e.response.data;
        if (err === 'e_email_not_allowed') {
          alert(
            'Your email is not allowed. Please contact Bumpy team to allow you in',
          );
        } else {
          alert('Error during signing in:' + err);
        }
        return;
      }
      alert('Unknown error during signing in');
    });
}

export function signOut(): void {
  if (confirm('Are you sure you want to sign out?')) {
    deleteToken();
    navigateToSign();
  }
}

export function isSignedIn(): boolean {
  return getToken() !== null;
}
