import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

type Props = {
  title: string;
  message: string;
  onYes: () => void;
  onNo: () => void;
};

export const YesNoDialog: React.FC<Props> = (props) => {
  return (
    <Dialog
      open={true}
      onClose={props.onNo}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onNo} autoFocus>
          No
        </Button>
        <Button onClick={props.onYes}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
};
