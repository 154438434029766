import React from 'react';

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
} from '@mui/material';

type Props = {
  autoSave: boolean;
  message: string;
  open: boolean;
  onPause: () => void;
  onAutoSave: (value: boolean) => void;
};

export const TranslationProgressDialog: React.FC<Props> = (props) => {
  return (
    <Dialog open={props.open}>
      <DialogTitle>{props.message}</DialogTitle>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginHorizontal: 5,
        }}
      >
        <CircularProgress sx={{marginBottom: 3}} />
      </Box>
      <FormControlLabel
        control={<Checkbox defaultChecked={props.autoSave} />}
        value={props.autoSave}
        label="Autosave every 5 keys"
        onChange={(_, autoSave) => {
          props.onAutoSave(autoSave);
        }}
      />
      <DialogActions>
        <Button onClick={props.onPause}>Pause</Button>
      </DialogActions>
    </Dialog>
  );
};
