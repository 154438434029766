import React from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';

import {emojiNumbers, filterWeirdSymbols} from '../../utils';

import {
  ExpandedKeys,
  ProjectTranslations,
  TranslationKeyResponseDTO,
  TranslationSource,
} from '@bumpy/i18n_common';

type Props = {
  expandedKeys: ExpandedKeys;
  keyName: string;
  translation: string;
  translations: ProjectTranslations;
  translationKeyDto: TranslationKeyResponseDTO | undefined;
  onKeyClick: (key: string) => void;
  setExpandedKeys: (expandedKeys: ExpandedKeys) => void;
  onTranslationChange: (key: string, translation: string | object) => void;
};

export const KeyTranslationRow: React.FC<Props> = (props) => {
  const {expandedKeys, keyName, translations, translationKeyDto} = props;
  const expanded = expandedKeys[keyName];

  let hasAllLangs = true;
  for (const lang of Object.keys(translations)) {
    if (!translations[lang][keyName]) {
      hasAllLangs = false;
      break;
    }
  }

  const fields: React.ReactNode[] = [
    <TableRow key={`main_${keyName}`} hover>
      <TableCell>
        <Stack direction="row" alignItems="center">
          <span
            onClick={() => {
              props.onKeyClick(props.keyName);
            }}
          >
            {keyName}
            {!hasAllLangs && (
              <span style={{fontWeight: 'bold', color: 'red'}}> *</span>
            )}
          </span>
          {translationKeyDto && (
            <IconButton
              size="small"
              onClick={() => {
                props.setExpandedKeys({
                  ...expandedKeys,
                  [keyName]: !expanded,
                });
              }}
            >
              {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </Stack>
      </TableCell>
      <TableCell>
        <TextField
          fullWidth
          margin="none"
          size="small"
          value={props.translation}
          onChange={(event) => {
            props.onTranslationChange(
              keyName,
              filterWeirdSymbols(event.target.value),
            );
          }}
        />
      </TableCell>
    </TableRow>,
  ];

  if (translationKeyDto) {
    const sourceRows: React.ReactNode[] = [];
    const variants: string[] = [];
    for (const source of Object.keys(
      translationKeyDto,
    ) as TranslationSource[]) {
      const tr = translationKeyDto[source];
      const variant = tr.tr[0];
      let emojiNumber: string;
      if (typeof variant === 'string') {
        const index = variants.indexOf(variant);
        if (index === -1) {
          variants.push(variant);
          emojiNumber = emojiNumbers[variants.length - 1];
        } else {
          emojiNumber = emojiNumbers[index];
        }
      } else {
        emojiNumber = '-';
      }

      sourceRows.push(
        <TableRow key={source}>
          <TableCell
            onClick={() => {
              props.onTranslationChange(keyName, variant);
            }}
          >
            {source}
          </TableCell>
          <TableCell>
            <Stack direction="row" alignItems="center">
              {emojiNumber}
              <Stack direction="column">
                <TextField
                  fullWidth
                  margin="none"
                  size="small"
                  contentEditable="false"
                  value={variant}
                />
                {tr.check && <span>{tr.check}</span>}
              </Stack>
            </Stack>
          </TableCell>
        </TableRow>,
      );
    }
    fields.push(
      <TableRow key={`extra_${keyName}`}>
        <TableCell style={{paddingLeft: 20, padding: 0}} colSpan={6}>
          <Collapse in={expanded} timeout="auto">
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell width={70}>Source</TableCell>
                  <TableCell>Translation</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{sourceRows}</TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>,
    );
  }

  return <>{fields}</>;
};
