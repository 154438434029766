const key = 'token';

export function saveToken(token: string): void {
  localStorage.setItem(key, token);
}

export function getToken(): string | null {
  return localStorage.getItem(key);
}

export function deleteToken(): void {
  localStorage.removeItem(key);
}
