import React from 'react';
import {NavLink, useNavigate} from 'react-router-dom';

import SaveIcon from '@mui/icons-material/Save';
import {Breadcrumbs, Button, Grid, Typography} from '@mui/material';

import {apiCall, hideLoader, showLoader} from '../../utils';

import {ProjectForm} from './ProjectForm';

import {Project, ProjectModifyDTO} from '@bumpy/i18n_common';

export const ProjectCreate: React.FC = () => {
  const navigate = useNavigate();
  const [project, setProject] = React.useState<ProjectModifyDTO>({
    name: '',
    format: 'i18n_json',
    git: false,
    defaultBranch: 'main',
    mainLang: 'en',
    inRepoPath: '/',
    googleTranslate: true,
    deepl: true,
    chatgpt: false,
    chatgptModel: 'gpt-3.5-turbo-1106',
    chatgptPrompt: 'Translate this into lang code ${toLang}: ${content}',
  });

  const onChange = (diff: Partial<Project>) => {
    setProject({
      ...project,
      ...diff,
    });
  };

  const onCreateClick = () => {
    if (!project.chatgpt && !project.deepl && !project.googleTranslate) {
      alert('At least one translator must be used');
      return;
    }

    showLoader('Creating project...');
    apiCall({
      method: 'POST',
      url: '/projects',
      data: project,
      secure: true,
    })
      .then(() => {
        alert('Project created');
        navigate('..');
      })
      .catch(() => {
        alert('Error during creating project');
      })
      .finally(hideLoader);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={10}>
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink to="/">Projects</NavLink>
          <Typography color="secondary">New Project</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={onCreateClick}
        >
          Create
        </Button>
      </Grid>
      <ProjectForm project={project} onChange={onChange} />
    </Grid>
  );
};
