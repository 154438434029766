import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';

type Props = {
  branch: string;
  open: boolean;
  onClose: () => void;
  onPull: (branch: string) => void;
};

export const PullDialog: React.FC<Props> = (props) => {
  const [branch, setBranch] = React.useState(props.branch);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Pull changes from git</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          fullWidth
          label="Git branch to pull"
          value={branch}
          variant="standard"
          onChange={(event) => {
            setBranch(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button
          onClick={() => {
            props.onPull(branch);
          }}
        >
          Pull
        </Button>
      </DialogActions>
    </Dialog>
  );
};
