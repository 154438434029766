import React from 'react';
import {useNavigate} from 'react-router-dom';
import {
  CredentialResponse,
  GoogleLogin,
  GoogleOAuthProvider,
} from '@react-oauth/google';

import {Stack, Typography} from '@mui/material';

import {signInWithGoogle} from '../../utils';

export const SignInWithGoogle: React.FC = () => {
  const navigate = useNavigate();

  const onGoogleLoginSuccess = (credentialResponse: CredentialResponse) => {
    signInWithGoogle(credentialResponse, navigate);
  };

  return (
    <Stack alignItems="center">
      <Typography marginBottom={1} variant="h3">
        Bumpy i18n login
      </Typography>
      <GoogleOAuthProvider clientId="882932835543-gnbcg0ka5l0l2ohfarkjka9rrul043dn.apps.googleusercontent.com">
        <GoogleLogin
          onSuccess={onGoogleLoginSuccess}
          onError={() => {
            alert('Something happens during Google login');
          }}
        />
      </GoogleOAuthProvider>
    </Stack>
  );
};
