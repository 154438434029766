import React from 'react';

import {Box, CircularProgress, Dialog, DialogTitle} from '@mui/material';

let _uglySetVisible: (show: boolean) => void | undefined;
let _uglySetText: (text: string | undefined) => void | undefined;

export function showLoader(text?: string): void {
  _uglySetVisible?.(true);
  _uglySetText?.(text);
}

export function hideLoader(): void {
  _uglySetVisible?.(false);
}

export const Loader: React.FC = () => {
  const [visible, setVisible] = React.useState(false);
  const [text, setText] = React.useState<string | undefined>(undefined);
  _uglySetVisible = setVisible;
  _uglySetText = setText;

  return (
    <Dialog open={visible}>
      <DialogTitle>{text ? text : 'Loading...'}</DialogTitle>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginHorizontal: 5,
        }}
      >
        <CircularProgress sx={{marginBottom: 3}} />
      </Box>
    </Dialog>
  );
};
