import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TranslateIcon from '@mui/icons-material/Translate';
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';

import {
  apiCall,
  areKeyTranslationsSame,
  emojiNumbers,
  filterWeirdSymbols,
  hideLoader,
  mainLangFirst,
  showLoader,
} from '../../utils';

import {
  KeyTranslation,
  Project,
  ProjectRevision,
  TranslationRequestDTO,
  TranslationResponseDTO,
  TranslationSource,
} from '@bumpy/i18n_common';

type Props = {
  activeKey: string;
  checkLang: string;
  project: Project;
  revision: ProjectRevision;
  onClose: () => void;
  onDeleteKey: () => void;
  onSave: (keyName: string, translation: KeyTranslation) => boolean;
};

type ExpandedLangs = {[lang: string]: boolean};

export const ActiveKeyDialog: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState(true);
  const [key, setKey] = React.useState(props.activeKey);
  const langsRef = React.useRef<string[]>([]);
  const originalTranslation = React.useRef<KeyTranslation>();
  const [translations, setTranslations] = React.useState<KeyTranslation>(() => {
    const langs = mainLangFirst(
      Object.keys(props.revision.translations) as string[],
      props.project.mainLang,
    );
    const result: KeyTranslation = {};
    for (const lang of langs) {
      result[lang] = props.revision.translations[lang][props.activeKey];
    }
    langsRef.current = langs;
    originalTranslation.current = result;
    return result;
  });
  const [expandedLangs, setExpandedLangs] = React.useState<ExpandedLangs>({});
  const [multilineLang, setMultilineLang] = React.useState('');
  const [translationDto, setTranslationDto] = React.useState<
    TranslationResponseDTO | undefined
  >();

  function translate(data: TranslationRequestDTO): void {
    showLoader('Translating...');
    apiCall({
      method: 'POST',
      url: '/translations',
      secure: true,
      data,
    })
      .then((response) => {
        const dto = response.data as TranslationResponseDTO;
        const newExpandedLangs: ExpandedLangs = {};
        const newTranslations: KeyTranslation = {
          ...translations,
        };
        for (const lang of Object.keys(dto)) {
          const translation = dto[lang][key];
          const sources = Object.keys(translation) as TranslationSource[];
          let matches = 1;
          const first = translation[sources[0]].tr[0];
          for (let j = 1; j < sources.length; ++j) {
            if (first === translation[sources[j]].tr[0]) {
              matches++;
            }
          }
          if (matches === sources.length) {
            newExpandedLangs[lang] = false;
            newTranslations[lang] = first;
          } else {
            newExpandedLangs[lang] = true;
          }
        }

        setTranslations(newTranslations);
        setTranslationDto(dto);
        setExpandedLangs(newExpandedLangs);
      })
      .finally(hideLoader);
  }

  const onTranslateSingleClick = (toLang: string) => {
    const fromLang = props.project.mainLang;
    const content = translations[fromLang];
    if (
      !content ||
      (typeof content === 'string' && content.trim().length < 2)
    ) {
      alert(`Please enter ${fromLang} valid text`);
      return;
    }
    translate({
      projectUuid: props.project.uuid,
      checkLang: props.checkLang,
      fromLang,
      content: {
        [key]: content,
      },
      toLangs: [toLang],
    });
  };

  const onTranslateClick = () => {
    const fromLang = props.project.mainLang;
    const content = translations[fromLang];
    if (
      !content ||
      (typeof content === 'string' && content.trim().length < 2)
    ) {
      alert(`Please enter ${fromLang} valid text`);
      return;
    }
    const toLangs = [...langsRef.current];
    const index = toLangs.indexOf(fromLang);
    if (index !== -1) {
      toLangs.splice(index, 1);
    }
    translate({
      projectUuid: props.project.uuid,
      checkLang: props.checkLang,
      fromLang,
      content: {
        [key]: content,
      },
      toLangs,
    });
  };

  const onSaveClick = () => {
    if (props.onSave(key, translations)) {
      setOpen(false);
    }
  };

  const onCancelClick = () => {
    setOpen(false);
    props.onClose();
  };

  const renderRow = (lang: string) => {
    const isString = typeof translations[lang] === 'string';
    const expanded = expandedLangs[lang];
    const fields: React.ReactNode[] = [
      <TableRow key={`main_${lang}`}>
        <TableCell>
          <Stack direction="row" alignItems="center">
            <span
              onClick={() => {
                setMultilineLang(lang);
              }}
            >
              {lang}
            </span>
            {translationDto && translationDto[lang] && (
              <IconButton
                size="small"
                onClick={() => {
                  setExpandedLangs({
                    ...expandedLangs,
                    [lang]: !expanded,
                  });
                }}
              >
                {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction="row-reverse">
            {lang !== props.project.mainLang && (
              <IconButton
                size="small"
                onClick={() => {
                  onTranslateSingleClick(lang);
                }}
              >
                <TranslateIcon />
              </IconButton>
            )}
            <TextField
              fullWidth
              multiline={lang === multilineLang}
              margin="none"
              size="small"
              value={
                isString
                  ? translations[lang]
                  : JSON.stringify(translations[lang])
              }
              onChange={(event) => {
                let value = filterWeirdSymbols(event.target.value);
                if (!isString) {
                  try {
                    value = JSON.parse(value);
                  } catch {}
                }
                setTranslations({
                  ...translations,
                  [lang]: value,
                });
              }}
            />
          </Stack>
        </TableCell>
      </TableRow>,
    ];

    if (translationDto) {
      const translationLang = translationDto[lang];
      if (translationLang) {
        const translationKey = translationLang[key];
        if (translationKey) {
          const sourceRows: React.ReactNode[] = [];
          const variants: string[] = [];
          for (const source of Object.keys(
            translationKey,
          ) as TranslationSource[]) {
            const tr = translationKey[source];
            const variant = tr.tr[0];
            let emojiNumber: string;
            if (typeof variant === 'string') {
              const index = variants.indexOf(variant);
              if (index === -1) {
                variants.push(variant);
                emojiNumber = emojiNumbers[variants.length - 1];
              } else {
                emojiNumber = emojiNumbers[index];
              }
            } else {
              emojiNumber = '-';
            }
            sourceRows.push(
              <TableRow key={source}>
                <TableCell
                  onClick={() => {
                    setTranslations({
                      ...translations,
                      [lang]: variant,
                    });
                  }}
                >
                  {source}
                </TableCell>
                <TableCell>
                  <Stack direction="row" alignItems="center">
                    {emojiNumber}
                    <Stack direction="column">
                      <TextField
                        fullWidth
                        margin="none"
                        size="small"
                        contentEditable="false"
                        value={variant}
                      />
                      {tr.check && <span>{tr.check}</span>}
                    </Stack>
                  </Stack>
                </TableCell>
              </TableRow>,
            );
          }
          fields.push(
            <TableRow key={`extra_${lang}`}>
              <TableCell style={{paddingLeft: 20, padding: 0}} colSpan={6}>
                <Collapse in={expanded} timeout="auto">
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell width={70}>Source</TableCell>
                        <TableCell>Translation</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{sourceRows}</TableBody>
                  </Table>
                </Collapse>
              </TableCell>
            </TableRow>,
          );
        }
      }
    }

    return fields;
  };

  return (
    <Dialog open={open} onClose={onCancelClick}>
      <DialogTitle>
        <Stack direction="row-reverse">
          <IconButton onClick={onTranslateClick}>
            <TranslateIcon />
          </IconButton>
          <TextField
            fullWidth
            value={key}
            size="small"
            label="Key"
            margin="none"
            onChange={(event) => {
              setKey(event.target.value);
            }}
          />
        </Stack>
      </DialogTitle>
      <DialogContent inputMode="text">
        <TableContainer sx={{maxHeight: 600}}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell width={100}>Lang</TableCell>
                <TableCell width={1200}>Translation</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{langsRef.current.map(renderRow)}</TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" flex={1} justifyContent="space-between">
          <Button
            color="warning"
            startIcon={<DeleteIcon />}
            onClick={props.onDeleteKey}
          >
            Delete key
          </Button>
          <Stack direction="row">
            <Button onClick={onCancelClick}>Cancel</Button>
            <Button
              disabled={
                key.trim().length === 0 ||
                (key === props.activeKey &&
                  areKeyTranslationsSame(
                    langsRef.current!,
                    translations,
                    originalTranslation.current!,
                  ))
              }
              onClick={onSaveClick}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
