import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';

type Props = {
  title: string;
  message: string;
  value?: string;
  onPositive: (value: string) => void;
  onNegative: () => void;
};

export const InputDialog: React.FC<Props> = (props) => {
  const [value, setValue] = React.useState<string>(props.value ?? '');

  return (
    <Dialog
      open={true}
      onClose={props.onNegative}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.message}
        </DialogContentText>
        <TextField
          autoFocus
          fullWidth
          margin="none"
          variant="standard"
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onNegative}>Cancel</Button>
        <Button
          onClick={() => {
            props.onPositive(value);
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
