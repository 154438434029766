import React from 'react';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import {ProjectFormat, ProjectModifyDTO} from '@bumpy/i18n_common';

type Props = {
  project: ProjectModifyDTO;
  onChange: (diff: Partial<ProjectModifyDTO>) => void;
};

export const ProjectForm: React.FC<Props> = ({project, onChange}) => {
  return (
    <>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Name"
          placeholder="My super duper project"
          value={project.name}
          onChange={(event) => {
            onChange({
              name: event.target.value,
            });
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          label="Main language"
          placeholder="en"
          value={project.mainLang}
          onChange={(event) => {
            onChange({
              mainLang: event.target.value,
            });
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <FormControlLabel
          control={<Checkbox defaultChecked={project.git} />}
          value={project.git}
          label="Git"
          onChange={(_, git) => {
            onChange({git});
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <FormControlLabel
          control={<Checkbox defaultChecked={project.googleTranslate} />}
          value={project.googleTranslate}
          label="GoogleTranslator"
          onChange={(_, googleTranslate) => {
            onChange({googleTranslate});
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <FormControlLabel
          control={<Checkbox defaultChecked={project.deepl} />}
          value={project.deepl}
          label="DeepL"
          onChange={(_, deepl) => {
            onChange({deepl});
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <FormControlLabel
          control={<Checkbox defaultChecked={project.chatgpt} />}
          value={project.chatgpt}
          label="ChatGPT"
          onChange={(_, chatgpt) => {
            onChange({chatgpt});
          }}
        />
      </Grid>
      {project.chatgpt && (
        <>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              label="ChatGPT model"
              helperText="https://platform.openai.com/docs/models"
              placeholder="gpt-4-1106-preview"
              value={project.chatgptModel ?? ''}
              onChange={(event) => {
                onChange({
                  chatgptModel: event.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              label="ChatGPT translate prompt"
              placeholder="Translate texts into lang codes ${toLangs}: ${content}"
              helperText="Vars: ${toLangs}, ${fromLang}, ${content}"
              value={project.chatgptPrompt ?? ''}
              onChange={(event) => {
                onChange({
                  chatgptPrompt: event.target.value,
                });
              }}
            />
          </Grid>
        </>
      )}
      {project.git && (
        <>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Git repository url"
              placeholder="git@github.com/orgname/repo.git"
              value={project.remoteUrl}
              onChange={(event) => {
                onChange({
                  remoteUrl: event.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              label="In repo path"
              placeholder="strings/localization"
              value={project.inRepoPath}
              onChange={(event) => {
                onChange({
                  inRepoPath: event.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              label="Default branch"
              placeholder="main/master/develop"
              value={project.defaultBranch}
              onChange={(event) => {
                onChange({
                  defaultBranch: event.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <FormControl>
              <InputLabel variant="standard" id="format-label">
                Format
              </InputLabel>
              <Select
                fullWidth
                value={project.format}
                variant="standard"
                onChange={(event) => {
                  onChange({
                    format: event.target.value as ProjectFormat,
                  });
                }}
              >
                <MenuItem value="i18n_json">I18N JSON</MenuItem>
                <MenuItem value="iOS_plist">iOS *.plist</MenuItem>
                <MenuItem value="screenshots">Screenshots</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              label="Validation setup project script"
              placeholder="git clone repo.git && npm i"
              value={project.validationSetupProjectScript ?? ''}
              onChange={(event) => {
                onChange({
                  validationSetupProjectScript: event.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              label="Validation run script"
              placeholder="git clone repo.git && npm i"
              value={project.validationRunScript ?? ''}
              onChange={(event) => {
                onChange({
                  validationRunScript: event.target.value,
                });
              }}
            />
          </Grid>
        </>
      )}
    </>
  );
};
