import React from 'react';
import {createRoot} from 'react-dom/client';
import {RouterProvider} from 'react-router-dom';

import LogoutIcon from '@mui/icons-material/Logout';
import {Button, Paper} from '@mui/material';

import {router} from './Router';
import {isSignedIn, Loader, signOut} from './utils';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <div>
      <Loader />
      {isSignedIn() && (
        <Button
          variant="outlined"
          color="primary"
          startIcon={<LogoutIcon />}
          onClick={signOut}
          style={{position: 'absolute', right: 30, top: 20}}
        >
          SignOut
        </Button>
      )}
      <div style={{display: 'flex', justifyContent: 'center', marginTop: 40}}>
        <Paper style={{maxWidth: 1000, flex: 1, padding: 30}}>
          <RouterProvider router={router} />
        </Paper>
      </div>
    </div>
  </React.StrictMode>,
);
