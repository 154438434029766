import {KeyTranslation, ProjectRevision} from '@bumpy/i18n_common';

export function areKeyTranslationsSame(
  langs: string[],
  a: KeyTranslation,
  b: KeyTranslation,
): boolean {
  for (const lang of langs) {
    if (a[lang] !== b[lang]) {
      return false;
    }
  }
  return true;
}

export function areRevisionsSame(
  a: ProjectRevision,
  b: ProjectRevision,
): boolean {
  if (a.keys.length !== b.keys.length) {
    return false;
  }
  for (let i = 0; i < a.keys.length; ++i) {
    if (a.keys[i] !== b.keys[i]) {
      return false;
    }
  }
  if (
    JSON.stringify(a.translationIndex) !== JSON.stringify(b.translationIndex)
  ) {
    return false;
  }

  const aLangs = Object.keys(a.translations);
  const bLangs = Object.keys(b.translations);
  if (aLangs.length !== bLangs.length) {
    return false;
  }
  for (let i = 0; i < aLangs.length; ++i) {
    if (aLangs[i] !== bLangs[i]) {
      return false;
    }
  }
  for (const lang of aLangs) {
    for (const key of a.keys) {
      const aValue = a.translations[lang][key];
      if (typeof aValue === 'string') {
        if (aValue !== b.translations[lang][key]) {
          return false;
        }
      } else if (
        JSON.stringify(aValue) !== JSON.stringify(b.translations[lang][key])
      ) {
        return false;
      }
    }
  }
  return true;
}
